
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';

export const onRouteUpdate = () => {
    if (window.location.hash.substring(1) && window.location.hash.substring(1).length > 2) {
        return
    }
    if (typeof window !== `undefined`) { window.scrollTo(0, 0) }
}

export const shouldUpdateScroll = args => {
    return false;
};
