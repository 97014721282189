exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-functional-js": () => import("./../../../src/pages/functional.js" /* webpackChunkName: "component---src-pages-functional-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installations-js": () => import("./../../../src/pages/installations.js" /* webpackChunkName: "component---src-pages-installations-js" */),
  "component---src-pages-mailing-list-js": () => import("./../../../src/pages/mailing-list.js" /* webpackChunkName: "component---src-pages-mailing-list-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-sculptures-js": () => import("./../../../src/pages/sculptures.js" /* webpackChunkName: "component---src-pages-sculptures-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-functional-js": () => import("./../../../src/templates/functional.js" /* webpackChunkName: "component---src-templates-functional-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-work-details-js": () => import("./../../../src/templates/workDetails.js" /* webpackChunkName: "component---src-templates-work-details-js" */)
}

